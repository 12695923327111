import { render, staticRenderFns } from "./mrfiktiv.vue?vue&type=template&id=500d33c0&"
import script from "./mrfiktiv.vue?vue&type=script&lang=ts&"
export * from "./mrfiktiv.vue?vue&type=script&lang=ts&"
import style1 from "./mrfiktiv.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports